import { requestService } from "../../../core/services/requestService";
import { TQuickTask } from "../../models/dashboard/pillars.model";
import SET_COMPLETE_TASK from "./mutations/set-complete-task.mutation";
import SET_FAVORITE_TASK from "./mutations/set-favorite-task.mutation";
import GET_QUICK_TASK from "./queries/get-quick-task.query";

class QuickTaskService {
  constructor() {}

  async setCompleteTask(id: string, isCompleted: boolean) {
    try {
      const result = requestService.mutate(SET_COMPLETE_TASK, {
        input: {
          taskId: id,
          isComplete: isCompleted,
        },
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async setFavoriteTask(id: string, isFavorite: boolean) {
    try {
      const result = requestService.mutate(SET_FAVORITE_TASK, {
        input: {
          taskId: id,
          isFavorite: isFavorite,
        },
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }

  async getQuickTaskById(taskId: string): Promise<TQuickTask | null> {
    try {
      const result = await requestService.get(GET_QUICK_TASK, {
        taskId,
      });
      return result.data.getQuickTask;
    } catch (error) {
      return null;
    }
  }
}

export default QuickTaskService;
