import { requestService } from "../../../core/services/requestService";
import { SupportContentTypeEnum } from "../../enum/support.enum";
import {
  ISupportContent,
  ISupportResponse,
  IUserSupportContent,
} from "../../models/support/support.model";
import COMPLETE_OR_FAVORITE_SUPPORT_CONTENT from "./mutations/complete-or-favorite-support-content.mutation";
import GET_USER_SUPPORT_CONTENT from "./mutations/get-user-support-content.query";
import GET_ALL_SUPPORT_CONTENT from "./queries/get-all-support-data.query";
import GET_SUPPORT_DATA from "./queries/get-support-data.query";

class SupportService {
  async getSupportData(): Promise<ISupportResponse | null> {
    try {
      const result = await requestService.getAll(GET_SUPPORT_DATA);
      return result.data.getSupportData;
    } catch (error: any) {
      console.error("Error fetching support data:", error.message);
      return null;
    }
  }

  async completeOrFavoriteSupportContent(
    _contentId: string,
    _contentTypeId: string,
    isCompleted: boolean,
    isFavorited: boolean
  ): Promise<void> {
    try {
      await requestService.mutate(COMPLETE_OR_FAVORITE_SUPPORT_CONTENT, {
        input: {
          _contentId,
          _contentTypeId,
          isCompleted,
          isFavorited,
        },
      });
    } catch (error: any) {
      console.error(
        "Error completing or favoriting support content:",
        error.message
      );
    }
  }

  async getUserSupportContent(
    _supportContentId: string
  ): Promise<IUserSupportContent | null> {
    try {
      const result = await requestService.get(GET_USER_SUPPORT_CONTENT, {
        _supportContentId,
      });
      return result.data.getUserSupportContent;
    } catch (error: any) {
      return null;
    }
  }

  async getAllSupportContent(
    _pillarId?: string | null,
    classificationType?: SupportContentTypeEnum | null,
    page?: number,
    perPage?: number
  ): Promise<ISupportContent[] | null> {
    try {
      const result = await requestService.get(GET_ALL_SUPPORT_CONTENT, {
        _pillarId,
        classificationType,
        page,
        perPage,
      });
      return result.data.getAllSupportContent;
    } catch (error: any) {
      console.error("Error fetching all support content:", error.message);
      return null;
    }
  }
}

export default SupportService;
