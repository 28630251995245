import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";
import { IoIosClose } from "react-icons/io";
import { QuickTaskTypeEnum } from "../../../../shared/enum/dashboardEnum";
import { TQuickTask } from "../../../../shared/models/dashboard/pillars.model";
import QuickTaskService from "../../../../shared/services/quickTasks/quickTasks.service";
import {
  CheckBtn,
  CheckBtnArea,
  CloseBtn,
  FavoriteButton,
  LeftArea,
  ModalContainer,
  ModalDescription,
  ModalIframe,
  ModalImg,
  ModalTitle,
  RightArea,
} from "./style";

import "./style.css";
type ModalProps = {
  showModal: boolean;
  closeModal: () => void;
  modalData: TQuickTask | null;
};

const TaskModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  modalData,
}) => {
  const _quickTaskService = new QuickTaskService();
  const [clicked, setClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  if (!modalData) {
    return <></>;
  }

  const setCompleteTask = async () => {
    modalData.isCompleted = !modalData.isCompleted;
    if (modalData._id) {
      await _quickTaskService.setCompleteTask(
        modalData._id,
        modalData.isCompleted
      );
    }
    setClicked(!clicked);
  };

  const setFavoriteTask = async () => {
    modalData.isFavorite = !modalData.isFavorite;
    if (modalData._id) {
      await _quickTaskService.setFavoriteTask(
        modalData._id,
        modalData.isFavorite
      );
    }
    setClicked(!clicked);
  };

  const handleMouseHelperEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseHelperLeave = () => {
    setShowTooltip(false);
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const videoId = getYouTubeVideoId(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  };

  const getYouTubeVideoId = (url: string) => {
    const videoIdMatch = url.match(/[?&]v=([^?&]+)/);
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
    return null;
  };

  const renderMedia = () => {
    if (modalData.type === QuickTaskTypeEnum.Image) {
      return <ModalImg src={modalData.link} alt="Imagem" />;
    } else if (modalData.type === QuickTaskTypeEnum.Video) {
      const embedUrl = getYouTubeEmbedUrl(modalData.link);
      return (
        <ModalIframe
          width="560"
          height="315"
          src={embedUrl}
          title="Vídeo do YouTube"
          frameBorder="0"
          allowFullScreen
        />
      );
    }
  };

  return (
    <Modal
      show={showModal}
      onHide={closeModal}
      size="lg"
      className="custom-modal "
    >
      <ModalContainer className={!isMobile ? "row" : ""}>
        <LeftArea className="col-6 position-relative">
          <FavoriteButton className="position-absolute p-3">
            <div
              onClick={setFavoriteTask}
              style={{
                backgroundColor: modalData.isFavorite ? "black" : "white",
              }}
              role="button"
              onMouseEnter={handleMouseHelperEnter}
              onMouseLeave={handleMouseHelperLeave}
              className="star p-1"
            >
              <OverlayTrigger
                placement="right"
                overlay={<Tooltip>Favoritar tarefa</Tooltip>}
                show={showTooltip}
              >
                {modalData.isFavorite ? (
                  <StarIcon
                    style={{
                      color: modalData.isFavorite ? "white" : "black",
                    }}
                  />
                ) : (
                  <StarOutlineIcon />
                )}
              </OverlayTrigger>
            </div>
          </FavoriteButton>
          {renderMedia()}
        </LeftArea>

        <RightArea className="col-6">
          <CloseBtn onClick={closeModal}>
            <IoIosClose
              size={28}
              style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
            />
          </CloseBtn>

          <ModalTitle className="animate__animated animate__fadeIn">
            {modalData?.title}
          </ModalTitle>
          <ModalDescription className="animate__animated animate__fadeIn animate__delay-0.5s">
            {modalData?.description}
          </ModalDescription>
          <CheckBtnArea className="animate__animated animate__fadeIn animate__delay-0.5s mt-5">
            <CheckBtn
              style={{
                float: "left",
                width: modalData.isCompleted ? "120px" : "auto",
                backgroundColor: modalData.isCompleted ? "#35DB88" : "#F5F5F5 ",
                color: modalData.isCompleted ? "#fff" : "black",
                transition: "all 0.4s ease",
              }}
              onClick={setCompleteTask}
            >
              {modalData.isCompleted ? " Concluído " : "Marcar como concluído "}
              <BsCheck2Circle
                className={modalData.isCompleted ? "d-none" : ""}
              ></BsCheck2Circle>
            </CheckBtn>
          </CheckBtnArea>
        </RightArea>
      </ModalContainer>
    </Modal>
  );
};

export default TaskModal;
