import { requestService } from "../../../core/services/requestService";
import {
  TAnswerInput,
  TQuestionResponse,
} from "../../models/questionnaire/questionnaire.modal";
import ANSWER_QUESTIONNAIRE from "./mutation/answer-questionnaire.mutation";
import LIST_QUESTIONS from "./querys/list-questions.query";

class QuestionnaireService {
  async listQuestions(
    page?: number,
    perPage?: number,
    sort?: string,
    sortDir?: string,
    filter?: string
  ): Promise<TQuestionResponse[]> {
    try {
      const { data } = await requestService.get(LIST_QUESTIONS, {
        page,
        perPage,
        sort,
        sortDir,
        filter,
      });
      return data.listQuestions;
    } catch (error: any) {
      console.log(error.message);
      return [];
    }
  }

  async answerQuestionnaire(data: TAnswerInput[]) {
    try {
      const result = requestService.mutate(ANSWER_QUESTIONNAIRE, {
        answer: data,
      });
      return result;
    } catch (error: any) {
      console.log(error.message);
    }
  }
}

export default QuestionnaireService;
