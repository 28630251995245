import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import React, { useEffect, useState } from "react";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BsCheck2Circle } from "react-icons/bs";
import { FaExternalLinkAlt } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import {
  CheckBtn,
  CheckBtnArea,
  CloseBtn,
  ContentButton,
  FavoriteButton,
  LeftArea,
  ModalContainer,
  ModalDescription,
  ModalIframe,
  ModalImg,
  ModalTitle,
  RightArea,
} from "./style";

import { ISupportContent } from "../../models/support/support.model";
import SupportService from "../../services/support/support.service";
import EmbedModal from "../embed-modal";
import "./style.css";
type ModalProps = {
  showModal: boolean;
  closeModal: () => void;
  modalData: ISupportContent | null;
};

const ContentModal: React.FC<ModalProps> = ({
  showModal,
  closeModal,
  modalData,
}) => {
  const [clicked, setClicked] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);
  const [showEmbedModal, setShowEmbedModal] = useState(false);
  const supportContentService = new SupportService();
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const checkIsMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);
  if (!modalData) {
    return <></>;
  }

  const setCompleteTask = async () => {
    modalData.isCompleted = !modalData.isCompleted;
    if (modalData._id) {
      await supportContentService.completeOrFavoriteSupportContent(
        modalData._id,
        modalData._contentId,
        modalData.isCompleted,
        modalData.isFavorite || false
      );
    }
    setClicked(!clicked);
  };

  const setFavoriteTask = async () => {
    modalData.isFavorite = !modalData.isFavorite;
    if (modalData._id) {
      await supportContentService.completeOrFavoriteSupportContent(
        modalData._id,
        modalData._contentId,
        modalData.isCompleted || false,
        modalData.isFavorite
      );
    }
    setClicked(!clicked);
  };

  const handleMouseHelperEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseHelperLeave = () => {
    setShowTooltip(false);
  };

  const getYouTubeEmbedUrl = (url: string) => {
    const videoId = getYouTubeVideoId(url);
    const embedUrl = `https://www.youtube.com/embed/${videoId}`;
    return embedUrl;
  };

  const getYouTubeVideoId = (url: string) => {
    const videoIdMatch = url.match(/[?&]v=([^?&]+)/);
    if (videoIdMatch) {
      return videoIdMatch[1];
    }
    return null;
  };

  const renderMedia = () => {
    if (modalData.linkImage) {
      return <ModalImg src={modalData.linkImage} alt="Imagem" />;
    } else {
      const embedUrl = getYouTubeEmbedUrl(modalData.contentLink || "");
      return (
        <ModalIframe
          width="560"
          height="315"
          src={embedUrl}
          title="Vídeo do YouTube"
          frameBorder="0"
          allowFullScreen
        />
      );
    }
  };

  return (
    <>
      {!showEmbedModal && (
        <Modal
          show={showModal}
          onHide={closeModal}
          size="lg"
          className="custom-modal"
        >
          <ModalContainer className={!isMobile ? "row" : ""}>
            <LeftArea className="col-6 position-relative">
              <FavoriteButton className="position-absolute p-3">
                <div
                  onClick={setFavoriteTask}
                  style={{
                    backgroundColor: modalData.isFavorite ? "black" : "white",
                  }}
                  role="button"
                  onMouseEnter={handleMouseHelperEnter}
                  onMouseLeave={handleMouseHelperLeave}
                  className="star p-1"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip>Favoritar Conteúdo</Tooltip>}
                    show={!isMobile ? showTooltip : false}
                  >
                    {modalData.isFavorite ? (
                      <StarIcon
                        style={{
                          color: modalData.isFavorite ? "white" : "black",
                        }}
                      />
                    ) : (
                      <StarOutlineIcon />
                    )}
                  </OverlayTrigger>
                </div>
              </FavoriteButton>
              {renderMedia()}
            </LeftArea>
            <RightArea className="col-6">
              <CloseBtn onClick={closeModal}>
                <IoIosClose
                  size={28}
                  style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
                />
              </CloseBtn>
              <ModalTitle className="animate__animated animate__fadeIn">
                {modalData?.name}
              </ModalTitle>
              <ModalDescription className="animate__animated animate__fadeIn animate__delay-0.5s">
                {modalData?.description}
              </ModalDescription>
              <CheckBtnArea className="animate__animated animate__fadeIn animate__delay-0.5s mt-5 d-flex align-items-center">
                {!modalData.isVideo && modalData.contentLink && (
                  <ContentButton
                    onClick={() => {
                      setShowEmbedModal(true);
                    }}
                    className="me-2"
                  >
                    <FaExternalLinkAlt />
                  </ContentButton>
                )}
                <CheckBtn
                  style={{
                    float: "left",
                    width: modalData.isCompleted ? "120px" : "auto",
                    backgroundColor: modalData.isCompleted
                      ? "#35DB88"
                      : "#F5F5F5 ",
                    color: modalData.isCompleted ? "#fff" : "black",
                    transition: "all 0.4s ease",
                  }}
                  onClick={setCompleteTask}
                >
                  {modalData.isCompleted
                    ? " Concluído "
                    : "Marcar como concluído "}
                  <BsCheck2Circle
                    className={modalData.isCompleted ? "d-none" : ""}
                  ></BsCheck2Circle>
                </CheckBtn>
              </CheckBtnArea>
            </RightArea>
          </ModalContainer>
        </Modal>
      )}
      {showEmbedModal && (
        <EmbedModal
          showModal={showEmbedModal}
          closeModal={() => {
            setShowEmbedModal(false);
          }}
          link={modalData?.contentLink || null}
          name={modalData?.name}
        />
      )}
    </>
  );
};

export default ContentModal;
