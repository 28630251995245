import "bootstrap/dist/css/bootstrap.css";
import { useEffect, useState } from "react";
import {
  BrowserRouter,
  Navigate,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import "./App.scss";
import TokenInterceptor from "./core/interceptors/token.interceptor";
import Activities from "./pages/activities";
import { ExerciseMemory } from "./pages/activities/mobile/pages/exerciseMemory";
import { QuickTaskMobile } from "./pages/activities/mobile/pages/quickTaskMobile";
import { SelfCareHabits } from "./pages/activities/mobile/pages/selfCareHabits";
import { SelfCareChoiceMobile } from "./pages/activities/mobile/pages/selfCareHabitsChoice";
import { ThoughtJornals } from "./pages/activities/mobile/pages/thoughtJornals";
import Calendar from "./pages/calendar";
import Chat from "./pages/chat";
import Configurations from "./pages/configurations";
import Contents from "./pages/contents";
import DashBoard from "./pages/dashboard";
import Help from "./pages/help";
import Historic from "./pages/historic";
import Monitoring from "./pages/monitoring";
import MyAccount from "./pages/my-account";
import Overview from "./pages/overview";
import Signup from "./pages/signup";
import Login from "./shared/components/login";
import QuestionnaireModal from "./shared/components/questionnaire";
import SideBar from "./shared/components/side-menu";
import MobileMenu from "./shared/components/side-menu/mobile";
import "./styles/colors.scss";

function App() {
  const [showQuestionnaireModal, setShowQuestionnaireModal] =
    useState<boolean>(false);
  const [isMobile, setIsMobile] = useState(false);

  const openQuestionnaireModal = () => {
    setShowQuestionnaireModal(true);
  };

  useEffect(() => {
    openQuestionnaireModal();
    const checkIsMobile = () => {
      setIsMobile(/Mobi|Android/i.test(navigator.userAgent));
    };
    checkIsMobile();
    window.addEventListener("resize", checkIsMobile);
    return () => {
      window.removeEventListener("resize", checkIsMobile);
    };
  }, []);

  return (
    <BrowserRouter>
      <AppContent
        showQuestionnaireModal={showQuestionnaireModal}
        setShowQuestionnaireModal={setShowQuestionnaireModal}
        isMobile={isMobile}
      />
    </BrowserRouter>
  );
}

function AppContent({
  showQuestionnaireModal,
  setShowQuestionnaireModal,
  isMobile,
}: {
  showQuestionnaireModal: boolean;
  setShowQuestionnaireModal: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
}) {
  const location = useLocation();
  const isLoginPage = location.pathname === "/login";
  const isSignupRoute = location.pathname === "/signup";

  return (
    <>
      {!isLoginPage && !isSignupRoute && (
        <QuestionnaireModal
          onClose={() => setShowQuestionnaireModal(false)}
          onShowQuestionnaire={function (show: boolean): void {
            setShowQuestionnaireModal(show);
          }}
        />
      )}
      <TokenInterceptor />
      {!isLoginPage &&
        !isSignupRoute &&
        !showQuestionnaireModal &&
        !isMobile && <SideBar></SideBar>}

      {!isLoginPage &&
        !isSignupRoute &&
        !showQuestionnaireModal &&
        isMobile && <MobileMenu />}

      <Routes>
        <Route path="/" element={<Navigate to="/dashboard" />} />
        <Route path="/login" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        {!showQuestionnaireModal && (
          <>
            <Route path="/dashboard" element={<DashBoard />} />
            <Route path="/monitoring" element={<Monitoring />} />
            <Route path="/historic" element={<Historic />} />
            <Route path="/calendar" element={<Calendar />} />
            <Route path="/activities" element={<Activities />} />
            <Route path="/chat" element={<Chat />} />
            <Route path="/overview" element={<Overview />} />
            <Route path="/configurations" element={<Configurations />} />
            <Route path="/help" element={<Help />} />
            <Route
              path="/activities/selfCareHabitsChoice"
              element={<SelfCareChoiceMobile />}
            />
            <Route
              path="/activities/selfCareHabitsChoice"
              element={<SelfCareChoiceMobile />}
            />
            <Route
              path="/activities/selfCareHabits/adopt"
              element={<SelfCareHabits />}
            />
            <Route
              path="/activities/selfCareHabits/abadon"
              element={<SelfCareHabits />}
            />
            <Route
              path="/activities/thoughtJornals"
              element={<ThoughtJornals />}
            />
            <Route
              path="/activities/exerciseMemory"
              element={<ExerciseMemory />}
            />
            <Route
              path="/contents"
              element={<Contents />}
            />
            <Route path="/activities/quickTask" element={<QuickTaskMobile />} />
            <Route path="/account" element={<MyAccount />} />
          </>
        )}
      </Routes>
    </>
  );
}

export default App;
