import styled from "styled-components";

export const MonitoringContainer = styled.div`
  width: 97vw;
  padding-left: 10rem;
  padding-right: 5rem;

  @media only screen and (max-height: 700px) {
    padding-right: 0;
  }
  @media (max-width: 762px) {
    width: 100vw;
    min-height: 140dvh;
    padding: 0 !important;
  }
`;
export const MainContent = styled.div`
  width: 100%;
  margin-top: 30px;
`;

export const LeftContent = styled.div`
  @media (max-width: 762px) {
    width: 100%;
    display: flex;
    justify-content: center;
  }
`;
export const RightContent = styled.div`
  position: relative;
`;

export const CareCard = styled.div`
  background-color: #27c275;
  height: 170px;
  border-radius: 14px;
  border: 1px solid #f6f6f6;
  padding: 20px;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
    width: 90%;
    height: 300px;
  }
`;

export const InspitarionCard = styled.div`
  background-color: white;
  height: 250px;
  border-radius: 14px;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #f6f6f6;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
    background: #F8F8F8;
    width: 90%;
    height: 400px;
  }
  position: relative;
  .next,
  .previous {
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    top: 50%;
    width: 50px;
    box-shadow: 5px 5px 20px #00000030;
    border: 1px solid #f6f6f6;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-size: 3rem;
    right: -25px;
    transition: 0.25s;
    svg {
      min-width: 45px;
      color: #01c2b5;
    }
    &:hover {
      background: #01c2b5;
      svg {
        color: white;
      }
    }
  }
  .previous {
    left: -25px !important;
    svg {
      transform: scaleX(-1);
    }
  }
`;
export const SuggestionCard = styled.div`
  background-color: white;
  height: 250px;
  border-radius: 14px;
  margin-top: 20px;
  padding: 20px;
  border: 1px solid #f6f6f6;
  box-shadow: 10px 15px 40px #3b4b761f;
  @media (max-width: 762px) {
    box-shadow: none;
    width: 90%;
    background: #f8f8f8;
    height: 300px;
  }
  position: relative;
  .nextSuggestion,
  .previousSuggestion {
    position: absolute;
    background: #ffffff 0% 0% no-repeat padding-box;
    top: 40%;
    width: 40px;
    height: 40px;
    box-shadow: 5px 5px 20px #00000030;
    border: 1px solid #f6f6f6;
    border-radius: 50%;
    text-align: center;
    display: flex;
    justify-content: center;
    cursor: pointer;
    align-items: center;
    font-size: 2rem;
    right: 0px;
    transition: 0.25s;
    svg {
      min-width: 45px;
      color: #01c2b5;
    }
    &:hover {
      background: #01c2b5;
      svg {
        color: white;
      }
    }
  }
  .previousSuggestion {
    left: -1rem !important;
    z-index: 100;
    svg {
      transform: scaleX(-1);
    }
  }
`;

export const RightCard = styled.div`
  height: 50%;
  border-radius: 14px;
  border: 1px solid #e9e9e9;
  box-shadow: 5px 5px 5px #cbced1;

  h1 {
    color: white;
    font-size: 1.9rem;
    @media only screen and (max-height: 700px) {
      font-size: 1rem;
    }
  }

  button {
    border-radius: 7px;
    font-weight: bold;
    border: none;
    background: white;
    transition: 0.25s;
    cursor: not-allowed;
  }

  &:first-child {
    background: #482aa7;
    button {
      color: #482aa7;
      &:hover {
        color: white;
        background: #19094b;
      }
    }
  }
  &:nth-child(2) {
    background: #01c2b5 0% 0% no-repeat padding-box;
    button {
      color: #01c2b5;
      background: #ffffff;
      &:hover {
        color: white;
        background: #008077;
      }
    }
  }
`;
export const CardLeftContent = styled.div`
  height: 100%;

  @media (max-width: 762px) {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`;

export const CardRightContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;
export const CardTitle = styled.span`
  background-color: black;
  color: white;
  border-radius: 5px;
  width: 100px;
  text-align: left;
  font: normal normal medium 20px/24px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  padding: 0 10px 2px;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const CardSubTitle = styled.span`
  text-align: left;
  font: normal normal medium 29px/34px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
  font-size: 29px;
`;
export const FirstCardMessage = styled.span`
  text-align: left;
  font: normal normal normal 18px/21px Raleway;
  letter-spacing: 0px;
  color: #ffffff;
  opacity: 1;
`;
export const CardMessage = styled.span`
  text-align: left;
  font: normal normal normal 18px/21px Raleway;
  letter-spacing: 0px;
  color: #000000;
  opacity: 1;
`;
export const BtnMeet = styled.button`
  color: white;
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: #03001c;
  opacity: 1;
  border-radius: 10px;
  padding: 10px;
  border: none;
  margin-top: -20px;
  transition: 0.25s;
  @media only screen and (max-height: 700px) {
    font-size: 0.7rem;
  }
  &:hover {
    background: white;
    color: black;
  }
`;

export const Pinguim = styled.img`
  width: 210px;
  right: -70px;
  bottom: -30px;
  position: absolute;
  @media only screen and (max-height: 700px) {
    right: -15px;
  }
`;

export const Assets = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  overflow: auto;
  overflow-y: hidden;
  height: 100%;
  flex-wrap: nowrap;
  @media (max-width: 768px) {
    justify-content: center;
  }
`;
export const AssetItem = styled.div`
  position: relative;
  width: 150px;
  height: 180px;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  margin-left: 1rem;
  padding: 8px 6px;
  border-radius: 14px;
  transition: 0.25s;
  cursor: pointer;
  @media (max-width: 762px) {
    margin-left: 0;
  }

  .description {
    opacity: 0;
    top: 0;
    left: 0;
    border-radius: 14px;
    position: absolute;
    width: 100%;
    height: 100%;
    transition: 0.25s;
    color: white;
  }
  &:hover {
    .description {
      opacity: 1;
      background: rgba(0, 0, 0, 0.336);
    }
  }
  .title {
    background: #35db88;
    font-weight: 600;
    text-align: center;
    border-radius: 13px;
  }
`;

export const AssetImg = styled.img`
  width: 100%;
  &:hover {
    background-color: black;
  }
`;

export const SeeMoreButton = styled.span`
  background-color: #f0f0f1;
  color: black;
  border-radius: 5px;
  width: 110px;
  text-align: left;
  font: normal normal medium 20px/24px Raleway;
  letter-spacing: 0px;
  opacity: 1;
  cursor: pointer;
  padding: 2px 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.25s;

  &:hover {
    background: black;
    color: white;
  }
`;
