import { gql } from "@apollo/client";

const GET_DASHBOARD_DATA = gql`
  query getDashboardData(
    $filterFavoritedTasks: Boolean
    $filterCompletedTasks: Boolean
    $filterCycleTasks: Boolean
  ) {
    getDashboardData(
      input: {
        filterFavoritedTasks: $filterFavoritedTasks
        filterCompletedTasks: $filterCompletedTasks
        filterCycleTasks: $filterCycleTasks
      }
    ) {
      pillarsData {
        pillars {
          _id
          name
          description
          value
          disabled
        }
        monthPerformanceValue
        overallMonthlyAssessment
        monthPerformance
      }
      tasksData {
        title
        _id
        description
        color
        type
        link
        isFavorite
        isCompleted
        expiresTime
      }
      moodData {
        mood
        typeMessage
        motivationalMessage
        sourceMessage
      }
      favoritesData {
        _id
        title
        description
        type
        color
        typeName
        _pillarId
        isVideo
        classificationType
        linkImage
        contentTypeId
        contentLink
      }
    }
  }
`;

export default GET_DASHBOARD_DATA;
