/* eslint-disable react-hooks/exhaustive-deps */
import {
  Button,
  CardActionArea,
  CardContent,
  CardMedia,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  ImageList,
  ImageListItem,
  InputLabel,
  MenuItem,
  Select,
  Skeleton,
  SpeedDial,
  SpeedDialAction,
  SpeedDialIcon,
  TextField,
  Typography,
} from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { FaTrash } from "react-icons/fa";
import { IoMdAdd, IoMdClose } from "react-icons/io";
import { MdEdit, MdFilterList } from "react-icons/md";
import ContentModal from "../../shared/components/modal-content";
import PageTitle from "../../shared/components/page-title";
import {
  MainContainer,
  TopCenterBall,
} from "../../shared/components/templates/main-container/main-container";
import { TPillar } from "../../shared/models/dashboard/pillars.model";
import { ISupportContent } from "../../shared/models/support/support.model";
import ContentService, {
  IContent,
} from "../../shared/services/contents/content.service";
import PillarService from "../../shared/services/pillars/pillars.service";
import SupportService from "../../shared/services/support/support.service";
import { MonitoringContainer } from "../monitoring/style";
import ModalInsertContent from "./components/content-insert-modal";
import { ContentsContainer } from "./style";

const actions = [
  { icon: <IoMdAdd />, name: "Adicionar" },
  { icon: <MdEdit />, name: "Editar" },
  { icon: <FaTrash />, name: "Remover" },
  { icon: <MdFilterList />, name: "Filtrar" },
];

export default function Contents() {
  const [showSupportContentModal, setShowSupportContentModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [deleteMode, setDeleteMode] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [itemData, setItemData] = useState<ISupportContent[]>([]);
  const [filterDialogOpen, setFilterDialogOpen] = useState(false);
  const [selectedContentType, setSelectedContentType] = useState("");
  const [selectedPillar, setSelectedPillar] = useState("");
  const [addContentDialogOpen, setAddContentDialogOpen] = useState(false);
  const supportService = new SupportService();
  const contentService = new ContentService();
  const [supportContentModalData, setSupportContentModalData] =
    useState<ISupportContent | null>(null);
  const [itemToDelete, setItemToDelete] = useState<ISupportContent | null>(
    null
  );
  const pillarService = new PillarService();
  const [pillars, setPillars] = useState<TPillar[]>([]);
  const [contents, setContents] = useState<IContent[]>([]);

  const [confirmDeleteDialogOpen, setConfirmDeleteDialogOpen] = useState(false);

  const handleSearchChange = (event: {
    target: { value: SetStateAction<string> };
  }) => {
    setSearchTerm(event.target.value);
  };

  const handleOpenFilterDialog = () => {
    setFilterDialogOpen(true);
  };

  const handleCloseFilterDialog = () => {
    setFilterDialogOpen(false);
  };

  const handleFilterChange = () => {
    setFilterDialogOpen(false);
  };

  const openSupportContentModal = async (item: ISupportContent) => {
    const result = await supportService.getUserSupportContent(item._id || "");
    item = {
      ...item,
      isFavorite: result?.isFavorited || false,
      isCompleted: result?.isCompleted || false,
    };
    setSupportContentModalData(item ? item : null);
    !editMode
      ? setShowSupportContentModal(true)
      : setAddContentDialogOpen(true);
  };

  const handleOpenAddContentDialog = () => {
    setSupportContentModalData(null);
    setAddContentDialogOpen(true);
  };

  const handleCloseAddContentDialog = () => {
    setAddContentDialogOpen(false);
  };

  const closeSupportContentModal = () => {
    setShowSupportContentModal(false);
  };

  const handleDeleteMode = () => {
    setDeleteMode(!deleteMode);
  };

  const handleEditMode = () => {
    setEditMode(!editMode);
  };

  const getPillars = async () => {
    setLoading(true);
    const pillarsContent = await pillarService.getAllPillars();
    setPillars(pillarsContent || []);
  };

  const getContents = async () => {
    const contentsData = await contentService.getAllContents(1, 300);
    setContents(contentsData || []);
  };

  const loadData = async () => {
    setLoading(true);
    await getPillars();
    await getContents();
    await loadMoreSupportContent();
    setLoading(false);
  };

  useEffect(() => {
    loadData();
  }, []);

  const loadMoreSupportContent = async () => {
    try {
      const additionalContent = await supportService.getAllSupportContent(
        null,
        null,
        1,
        500
      );
      setItemData(additionalContent || []);
    } catch (error) {
      console.error("Error loading more support content:", error);
    }
  };

  const filteredItems = itemData.filter(
    (item) =>
      item.name.toLowerCase().includes(searchTerm.toLowerCase()) &&
      (selectedContentType ? item._contentId === selectedContentType : true) &&
      (selectedPillar ? item._pillarId === selectedPillar : true)
  );

  const isBase64 = (str: string) => {
    try {
      return str.startsWith("data:image");
    } catch (err) {
      return false;
    }
  };

  const handleDeleteConfirm = async () => {
    if (itemToDelete) {
      try {
        await contentService.deleteSupportContent({
          supportContentId: itemToDelete._id || "",
        });
      } catch (error) {
        setLoading(false);
        return;
      }
    }
    setConfirmDeleteDialogOpen(false);
    await loadMoreSupportContent();
  };

  const handleDeleteClick = (item: ISupportContent) => {
    setItemToDelete(item);
    setConfirmDeleteDialogOpen(true);
  };

  return (
    <ContentsContainer>
      <MainContainer className="position-relative">
        {!deleteMode && !editMode && (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={<SpeedDialIcon />}
            FabProps={{
              sx: {
                bgcolor: "#00df70",
                "&:hover": {
                  bgcolor: "#00df70",
                },
              },
            }}
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={
                  action.name === "Filtrar"
                    ? handleOpenFilterDialog
                    : action.name === "Adicionar"
                    ? handleOpenAddContentDialog
                    : action.name === "Remover"
                    ? handleDeleteMode
                    : action.name === "Editar"
                    ? handleEditMode
                    : undefined
                }
              />
            ))}
          </SpeedDial>
        )}
        {editMode && (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={<IoMdClose />}
            onClick={() => {
              setEditMode(false);
            }}
            FabProps={{
              sx: {
                bgcolor: "#005586",
                "&:hover": {
                  bgcolor: "#005586",
                },
              },
            }}
          ></SpeedDial>
        )}
        {deleteMode && (
          <SpeedDial
            ariaLabel="SpeedDial basic example"
            sx={{ position: "absolute", bottom: 16, right: 16 }}
            icon={<IoMdClose />}
            onClick={() => {
              setDeleteMode(false);
            }}
            FabProps={{
              sx: {
                bgcolor: "#FF1901",
                "&:hover": {
                  bgcolor: "#FF1901",
                },
              },
            }}
          ></SpeedDial>
        )}
        <TopCenterBall />
        <MonitoringContainer>
          <PageTitle
            prop={{
              title: "Conteúdos",
              subtitle:
                "Esse quadro apresenta todos os conteúdos dando a possibilidade de manipular e criar.",
            }}
          />
          <TextField
            label="Buscar"
            variant="outlined"
            fullWidth
            margin="normal"
            value={searchTerm}
            onChange={handleSearchChange}
          />
          <ImageList
            gap={20}
            className="mt-3"
            sx={{ width: "85dvw", maxHeight: "63vh" }}
            cols={4}
          >
            {loading
              ? Array.from(new Array(12)).map((_, index) => (
                  <ImageListItem key={index}>
                    <Card className="card-content" style={{ height: "300px" }}>
                      <CardActionArea>
                        <Skeleton
                          variant="rectangular"
                          width="100%"
                          height={150}
                        />
                        <CardContent
                          style={{ height: 150, overflow: "hidden" }}
                        >
                          <Skeleton
                            variant="text"
                            width="80%"
                            height={30}
                            style={{ marginBottom: 10 }}
                          />
                          <Skeleton variant="text" width="60%" height={20} />
                          <Skeleton variant="text" width="60%" height={20} />
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </ImageListItem>
                ))
              : filteredItems.map((item) => (
                  <ImageListItem key={item._id}>
                    <Card
                      onClick={() => {
                        if (deleteMode) {
                          handleDeleteClick(item);
                        } else {
                          openSupportContentModal(item);
                        }
                      }}
                      className="card-content"
                      style={{
                        height: "300px",
                        overflow: "hidden",
                        backgroundColor: `${item.color}30`,
                      }}
                    >
                      <CardActionArea>
                        <CardMedia
                          component="img"
                          height="150"
                          image={`${
                            item.linkImage && isBase64(item.linkImage)
                              ? `${item.linkImage}`
                              : item.linkImage
                              ? `${item.linkImage}?w=164&h=164&fit=crop&auto=format`
                              : item?.contentLink?.includes("youtube.com")
                              ? `https://img.youtube.com/vi/${new URLSearchParams(
                                  new URL(item.contentLink).search
                                ).get("v")}/hqdefault.jpg`
                              : "defaultImage.jpg"
                          }`}
                          alt={item.name}
                        />
                        <CardContent
                          style={{ height: 150, overflow: "hidden" }}
                        >
                          <Typography gutterBottom variant="h5" component="div">
                            {item.name}
                          </Typography>
                          <Typography
                            style={{
                              overflow: "hidden",
                              maxHeight: "80px",
                              textOverflow: "ellipsis",
                            }}
                            variant="body2"
                            color="text.secondary"
                          >
                            {item.description}
                          </Typography>
                        </CardContent>
                      </CardActionArea>
                    </Card>
                  </ImageListItem>
                ))}
          </ImageList>
        </MonitoringContainer>
      </MainContainer>

      <Dialog
        fullWidth={true}
        open={filterDialogOpen}
        onClose={handleCloseFilterDialog}
      >
        <DialogTitle>Filtrar Conteúdos</DialogTitle>
        <DialogContent>
          <FormControl fullWidth margin="normal">
            <InputLabel>Tipo de Conteúdo</InputLabel>
            <Select
              value={selectedContentType}
              onChange={(e) => setSelectedContentType(e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              {contents.map((content) => {
                return <MenuItem value={content._id}>{content.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
          <FormControl fullWidth margin="normal">
            <InputLabel>Pilar</InputLabel>
            <Select
              value={selectedPillar}
              onChange={(e) => setSelectedPillar(e.target.value)}
            >
              <MenuItem value="">Todos</MenuItem>
              {pillars.map((pillar) => {
                return <MenuItem value={pillar._id}>{pillar.name}</MenuItem>;
              })}
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFilterDialog}>Cancelar</Button>
          <Button onClick={handleFilterChange} color="primary">
            Filtrar
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        fullWidth={true}
        open={confirmDeleteDialogOpen}
        onClose={() => {
          setConfirmDeleteDialogOpen(false);
        }}
      >
        <DialogTitle>Deletar Conteúdo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Tem certeza de que deseja deletar esse conteúdo?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => setConfirmDeleteDialogOpen(false)}
            color="primary"
          >
            Cancelar
          </Button>
          <Button onClick={handleDeleteConfirm} color="error">
            Deletar
          </Button>
        </DialogActions>
      </Dialog>

      <ModalInsertContent
        showModal={addContentDialogOpen}
        closeModal={handleCloseAddContentDialog}
        refresh={() => {
          loadMoreSupportContent();
        }}
        modalData={supportContentModalData || null}
        pillars={pillars}
        contents={contents}
      />
      <ContentModal
        showModal={showSupportContentModal}
        closeModal={closeSupportContentModal}
        modalData={supportContentModalData}
      />
    </ContentsContainer>
  );
}
