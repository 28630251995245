/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import { Dropdown, Modal } from "react-bootstrap";
import { AiOutlinePlusCircle } from "react-icons/ai";
import { BiCalendarHeart } from "react-icons/bi";
import { BsChatSquareDots } from "react-icons/bs";
import { FaPrayingHands } from "react-icons/fa";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdOutlineContentCopy, MdOutlineMedicalServices } from "react-icons/md";
import { RiPulseFill } from "react-icons/ri";
import { RingLoader } from "react-spinners";
import ContentModal from "../../../../shared/components/modal-content";
import { SupportContentTypeEnum } from "../../../../shared/enum/support.enum";
import { ISupportContent } from "../../../../shared/models/support/support.model";
import PillarService from "../../../../shared/services/pillars/pillars.service";
import SupportService from "../../../../shared/services/support/support.service";
import { SeeMoreButton } from "../../style";
import { DropdownContainer, SeeMoreModalContainer } from "./style";

type SeeMoreModalProps = {
  showModal: boolean;
  closeModal: () => void;
  category: SupportContentTypeEnum;
};

enum PillarDashboardEnum {
  Essential = "Essencial",
  Emotional = "Emocional",
  Spiritual = "Espiritual",
  Biological = "Biológico",
  Social = "Social",
}

interface DropdownItem {
  id: string | null;
  label: string;
  icon: JSX.Element;
  color?: string;
}

const SeeMoreModal: React.FC<SeeMoreModalProps> = ({
  showModal,
  closeModal,
  category,
}) => {
  const [selectedOption, setSelectedOption] = useState<DropdownItem>({
    id: null,
    label: "Todos",
    icon: <MdOutlineContentCopy size={25} />,
  });
  const [showSupportContentModal, setShowSupportContentModal] = useState(false);
  const [loading, setLoading] = useState<boolean>(false);
  const [supportContent, setSupportContent] = useState<ISupportContent[]>([]);
  const [dropdownItems, setDropdownItems] = useState<DropdownItem[]>([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [supportContentModalData, setSupportContentModalData] =
    useState<ISupportContent | null>(null);
  const supportService = new SupportService();
  const pillarService = new PillarService();
  const [selectedCategory, setSelectedCategory] =
    useState<SupportContentTypeEnum>(category);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const iconMappings: Record<PillarDashboardEnum, JSX.Element> = {
    [PillarDashboardEnum.Essential]: (
      <MdOutlineMedicalServices color="#01C2B5" size={30} />
    ),
    [PillarDashboardEnum.Emotional]: (
      <BiCalendarHeart color="#482AA7" size={30} />
    ),
    [PillarDashboardEnum.Spiritual]: (
      <FaPrayingHands color="#888888" size={30} />
    ),
    [PillarDashboardEnum.Biological]: <RiPulseFill color="#35DB88" size={30} />,
    [PillarDashboardEnum.Social]: (
      <BsChatSquareDots color="#5B8FB9" size={30} />
    ),
  };
  const isMobile: boolean = /Mobi|Android/i.test(navigator.userAgent);
  const closeSupportContentModal = () => {
    setShowSupportContentModal(false);
  };

  const colorMappings: Record<PillarDashboardEnum, string> = {
    [PillarDashboardEnum.Essential]: "#01C2B5",
    [PillarDashboardEnum.Emotional]: "#482AA7",
    [PillarDashboardEnum.Spiritual]: "#888888",
    [PillarDashboardEnum.Biological]: "#35DB88",
    [PillarDashboardEnum.Social]: "#5B8FB9",
  };

  const [isEndOfPage, setIsEndOfPage] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const { scrollTop, scrollHeight, clientHeight } =
        document.documentElement;
      if (scrollTop + clientHeight >= scrollHeight - 20) {
        setIsEndOfPage(true);
      } else {
        setIsEndOfPage(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isEndOfPage && supportContent.length > 0) {
      loadMoreSupportContent();
    }
  }, [isEndOfPage]);

  const loadMoreSupportContent = async () => {
    try {
      const nextPage = currentPage + 1;
      const additionalContent = await supportService.getAllSupportContent(
        selectedOption.id,
        selectedCategory,
        nextPage
      );

      if (additionalContent && additionalContent.length > 0) {
        setSupportContent((prevContent) => [
          ...prevContent,
          ...additionalContent,
        ]);
        setCurrentPage(nextPage);
      } else {
        setIsEndOfPage(false);
      }
    } catch (error) {
      console.error("Error loading more support content:", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      const content =
        (await supportService.getAllSupportContent(
          selectedOption.id,
          selectedCategory
        )) || [];
      const pillarsContent = await pillarService.getAllPillars();
      const newDropdownItems: DropdownItem[] = [
        {
          id: null,
          label: "Todos",
          icon: <MdOutlineContentCopy size={25} />,
        },
        ...(pillarsContent?.map((pillar) => ({
          id: pillar._id || "",
          label: pillar.name,
          icon: iconMappings[pillar.name as PillarDashboardEnum],
          color: colorMappings[pillar.name as PillarDashboardEnum],
        })) || []),
      ];
      setDropdownItems(newDropdownItems || []);
      setSupportContent(content);
      setLoading(false);
    };
    if (showModal) {
      fetchData();
    }
  }, [showModal, selectedCategory, selectedOption]);

  useEffect(() => {
    if (!showModal) {
      setSupportContent([]);
    }
  }, [!showModal]);

  const handleSelect = (selectedItem: DropdownItem) => {
    setSelectedOption(selectedItem);
  };

  const handleCategorySelect = (category: SupportContentTypeEnum) => {
    setLoading(true);
    setSupportContent([]);
    setSelectedCategory(category);
  };

  const openSupportContentModal = async (item: ISupportContent) => {
    const result = await supportService.getUserSupportContent(item._id || "");
    item = {
      ...item,
      isFavorite: result?.isFavorited || false,
      isCompleted: result?.isCompleted || false,
    };
    setSupportContentModalData(item ? item : null);
    setShowSupportContentModal(true);
  };

  return (
    <>
      {!showSupportContentModal && (
        <SeeMoreModalContainer
          show={showModal}
          onHide={closeModal}
          size="lg"
          className="custom-modal"
        >
          <Modal.Header
            style={
              isMobile
                ? {
                    transform: "scale(0.7)",
                    minWidth: "120vw",
                    position: "relative",
                    left: "-13vw",
                  }
                : {}
            }
            className="d-flex align-items-center"
            closeButton
          >
            <DropdownContainer style={{ height: 40 }}>
              <Dropdown
                onToggle={(isOpen) => setIsDropdownOpen(isOpen)}
                onSelect={(eventKey: any) =>
                  handleSelect(
                    dropdownItems.find((item) => item.label === eventKey)!
                  )
                }
              >
                <Dropdown.Toggle
                  style={{ background: selectedOption.color }}
                  id="dropdown-basic"
                  color={selectedOption.color}
                  className="px-3"
                >
                  <div className="d-flex align-items-center">
                    {React.cloneElement(selectedOption.icon, {
                      className: !isDropdownOpen && "white-icon",
                    })}
                    <span className="ms-3">
                      {isMobile ? "" : selectedOption.label}
                    </span>
                  </div>
                  {isDropdownOpen ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </Dropdown.Toggle>
                <Dropdown.Menu>
                  {dropdownItems.map((item) => (
                    <Dropdown.Item key={item.id} eventKey={item.label}>
                      {item.icon} {item.label}
                    </Dropdown.Item>
                  ))}
                </Dropdown.Menu>
              </Dropdown>
            </DropdownContainer>
            <div className="category-selector">
              <button
                className={
                  selectedCategory === SupportContentTypeEnum.Care
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleCategorySelect(SupportContentTypeEnum.Care)
                }
              >
                Cuidado
              </button>
              <button
                className={
                  selectedCategory === SupportContentTypeEnum.Inspiration
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleCategorySelect(SupportContentTypeEnum.Inspiration)
                }
              >
                Inspiração
              </button>
              <button
                className={
                  selectedCategory === SupportContentTypeEnum.Suggestions
                    ? "active"
                    : ""
                }
                onClick={() =>
                  handleCategorySelect(SupportContentTypeEnum.Suggestions)
                }
              >
                Sugestões
              </button>
            </div>
          </Modal.Header>
          {loading && (
            <div className="w-100 h-100 justify-content-center d-flex align-items-center">
              <RingLoader color="#00df70" loading={true} />
            </div>
          )}
          {!loading && (
            <div className="content-modal p-4 w-100 h-100">
              {supportContent.length > 0 &&
                supportContent?.map((item) => {
                  return (
                    <div
                      style={{ cursor: "pointer" }}
                      key={item._id}
                      onClick={() => {
                        openSupportContentModal(item);
                      }}
                      className="item-container mb-3 p-4"
                    >
                      <div className="item-content d-flex align-items-center justify-content-between">
                        <img
                          className="item-image"
                          src={item.linkImage}
                          alt="imagem"
                        />
                        <p className="item-description w-50 m-0 mx-3">
                          {item.description}
                        </p>
                        <SeeMoreButton
                          key={item._id}
                          role="button"
                          onClick={() => {
                            openSupportContentModal(item);
                          }}
                          className="ms-2 w-20 see-more"
                        >
                          <AiOutlinePlusCircle
                            size={15}
                            style={{ marginRight: "5px" }}
                          />
                          Ver mais
                        </SeeMoreButton>
                      </div>
                    </div>
                  );
                })}
              {supportContent.length === 0 && !loading && (
                <div className="d-flex justify-content-center align-items-center h-100 w-100">
                  <h3>Nenhum item encontrado.</h3>
                </div>
              )}
            </div>
          )}
        </SeeMoreModalContainer>
      )}
      <ContentModal
        showModal={showSupportContentModal}
        closeModal={closeSupportContentModal}
        modalData={supportContentModalData}
      />
    </>
  );
};

export default SeeMoreModal;
