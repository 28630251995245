/* eslint-disable @typescript-eslint/no-useless-constructor */
import { requestService } from "../../../core/services/requestService";
import { IDashboard } from "../../models/dashboard/dashboard.model";
import GET_DASHBOARD_DATA from "./querys/getDashboard.query";

class DashboardService {
  constructor() {}

  async getDashboard(
    filterCompletedTasks: boolean,
    filterFavoritedTasks: boolean,
    filterCycleTasks: boolean
  ): Promise<IDashboard | null> {
    try {
      const result = await requestService.get(GET_DASHBOARD_DATA, {
        filterCompletedTasks,
        filterFavoritedTasks,
        filterCycleTasks,
      });
      return result?.data?.getDashboardData;
    } catch (error: any) {
      console.log(error.message);
      return null;
    }
  }
}

export default DashboardService;
