/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-useless-escape */
import React, { useEffect, useState } from "react";
import { Modal, ModalBody, ModalHeader } from "react-bootstrap";
import { BiCalendarHeart } from "react-icons/bi";
import { BsChatSquareDots } from "react-icons/bs";
import { FaPrayingHands } from "react-icons/fa";
import { IoIosClose } from "react-icons/io";
import { MdOutlineMedicalServices } from "react-icons/md";
import { RiPulseFill } from "react-icons/ri";
import { RingLoader } from "react-spinners";
import { PillarDashboardEnum } from "../../../../shared/enum/dashboardEnum";
import { MessageServices } from "../../../../shared/services/message/message.service";
import { PillarCenterCard } from "../../../dashboard/components/pillar/style";

import { SupportContentTypeEnum } from "../../../../shared/enum/support.enum";
import { TPillar } from "../../../../shared/models/dashboard/pillars.model";
import { ISupportContent } from "../../../../shared/models/support/support.model";
import ContentService, {
  IContent,
} from "../../../../shared/services/contents/content.service";
import {
  CloseBtn,
  ModalBtnAdd,
  ModalContainer,
  ModalTitle,
} from "../../../activities/components/modal-care/style";
import { ModalPillars } from "../../../activities/components/modal-insert-task/style";
import "./style.css";

export enum ContentMediaEnum {
  Image = 1,
  Video = 2,
}
export type TModalProps = {
  showModal: boolean;
  closeModal: () => void;
  modalData: ISupportContent | null;
  refresh?: () => void;
  pillars: TPillar[];
  contents: IContent[];
};

const ModalInsertContent: React.FC<TModalProps> = ({
  showModal,
  closeModal,
  modalData,
  refresh,
  contents,
  pillars,
}) => {
  const [loading, setLoading] = useState(false);
  const [name, setName] = useState("" || modalData?.name);
  const [img, setImg] = useState("" || modalData?.linkImage);
  const [description, setDescription] = useState("" || modalData?.description);
  const [contentLink, setLink] = useState("" || modalData?.contentLink);

  const [classificationType, setClassificationType] =
    useState<SupportContentTypeEnum | null>(
      modalData?.classificationType || null
    );
  const [contentType, setContentType] = useState<IContent | null>(null);
  const [speciality, setSpeciality] = useState<IContent | null>(null);
  const [linkError, setLinkError] = useState("");
  const [imgError, setImgError] = useState("");
  const [selectedPillarId, setSelectedPillarId] = useState<string | null>(
    modalData?._pillarId || null
  );
  const [nameError, setNameError] = useState("");
  const [descriptionError, setDescriptionError] = useState("");
  const [specialities, setSpecialities] = useState<IContent[]>([]);
  const messageService = new MessageServices();
  const _contentService = new ContentService();

  const setNameValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = event.target.value;
    if (value.length <= 20) {
      setName(value);
      setNameError("");
    } else {
      setNameError("O título não pode ter mais de 20 caracteres.");
    }
  };

  const setDescriptionValue = (
    event: React.ChangeEvent<HTMLTextAreaElement>
  ) => {
    const value = event.target.value;
    if (value.length <= 80) {
      setDescription(value);
      setDescriptionError("");
    } else {
      setDescriptionError("A descrição não pode ter mais de 80 caracteres.");
    }
  };
  const setLinkValue = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLink(event.target.value);
    setLinkError("");
    setImgError("");
  };

  const setTypeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    setClassificationType(
      parseInt(event.target.value) as SupportContentTypeEnum
    );
  };

  const setContentTypeValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (contents.length > 0) {
      setContentType(
        contents?.find((c) => c._id === event.target.value) || null
      );
    }
  };
  const setSpecialityValue = (event: React.ChangeEvent<HTMLSelectElement>) => {
    if (specialities.length > 0) {
      setSpeciality(
        specialities?.find((c) => c._id === event.target.value) || null
      );
    }
  };

  const isLinkValid = (url: string): boolean => {
    const urlRegExp = /^(https?:\/\/)?([^\s$.?#].[^\s]*)$/i;
    return urlRegExp.test(url);
  };

  const isYouTubeLink = (url: string): boolean => {
    const youtubeRegExp = /^(https?\:\/\/)?(www\.youtube\.com|youtu\.?be)\/.+$/;
    return youtubeRegExp.test(url);
  };

  const handleImageUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files && event.target.files[0];
    if (file) {
      if (file.size > 2 * 1024 * 1024) {
        setImgError("A imagem não pode ser maior que 2MB.");
      } else {
        const reader = new FileReader();
        reader.onloadend = () => {
          const base64String = reader.result as string;
          setImg(base64String);
          setImgError("");
        };
        reader.readAsDataURL(file);
      }
    }
  };

  const changeClassNamePillar = (pillarName: string) => {
    switch (pillarName) {
      case PillarDashboardEnum.Essential:
        return "essential";
      case PillarDashboardEnum.Emotional:
        return "blue-puple";
      case PillarDashboardEnum.Spiritual:
        return "gray";
      case PillarDashboardEnum.Biological:
        return "green";
      case PillarDashboardEnum.Social:
        return "blue-gray";
      default:
        return "gray";
    }
  };

  const handlePillarClick = (_pillarId: string) => {
    setSelectedPillarId(_pillarId);
  };

  const changeIconPillar = (pillarName: string) => {
    switch (pillarName) {
      case PillarDashboardEnum.Essential:
        return <MdOutlineMedicalServices size={40} />;
      case PillarDashboardEnum.Emotional:
        return <BiCalendarHeart size={40} />;
      case PillarDashboardEnum.Spiritual:
        return <FaPrayingHands size={40} />;
      case PillarDashboardEnum.Biological:
        return <RiPulseFill size={40} />;
      case PillarDashboardEnum.Social:
        return <BsChatSquareDots size={40} />;
      default:
        return <RiPulseFill size={40} />;
    }
  };

  const getSpecialities = async () => {
    setLoading(true);
    const specialitiesData = await _contentService.getAllSpecialities();
    setSpecialities(specialitiesData || []);
  };

  const loadData = async () => {
    setLoading(true);
    await getSpecialities();
    setLoading(false);
  };

  const saveData = async (): Promise<void> => {
    setLoading(true);
    const isYouTube = isYouTubeLink(contentLink || "");
    if (contentLink && !isLinkValid(contentLink || "")) {
      setLinkError(`O Link fornecido não é válido.`);
      setLoading(false);
      return;
    }

    if (!name || !description || (!img && !isYouTube)) {
      if (!name) setNameError("Campo Título é obrigatório");
      if (!description) setDescriptionError("Campo Descrição é obrigatório");
      if (!img && !isYouTube)
        setImgError(
          "Campo Imagem é obrigatório se o link não for um vídeo do YouTube"
        );
      setLoading(false);
      return;
    }

    if (!selectedPillarId) {
      messageService.error("Erro", "Por favor, selecione um pilar.");
      setLoading(false);
      return;
    }
    if (!modalData) {
      try {
        await _contentService.createSupportContent({
          _contentId: contentType?._id || "",
          _pillarId: selectedPillarId,
          linkImage: img,
          contentLink: contentLink || "",
          description: description,
          classificationType:
            classificationType || SupportContentTypeEnum.Suggestions,
          color:
            pillars.find((pillar) => pillar._id === selectedPillarId)?.color ||
            "",
          name: name,
          speciality: speciality?._id || null,
        });
      } catch (err: any) {
        messageService.error("Erro", err?.message || "");
        setLoading(false);
        return;
      }
    }
    if (modalData) {
      try {
        await _contentService.editSupportContent({
          supportContentId: modalData?._id,
          _contentId: contentType?._id || "",
          _pillarId: selectedPillarId,
          contentLink: contentLink || "",
          linkImage: img,
          description: description,
          classificationType:
            classificationType || SupportContentTypeEnum.Suggestions,
          color:
            pillars.find((pillar) => pillar._id === selectedPillarId)?.color ||
            "",
          name: name,
          speciality: speciality?._id || null,
        });
      } catch (err: any) {
        messageService.error("Erro", err?.message || "");
        setLoading(false);
        return;
      }
    }
    refresh && refresh();
    messageService.success(
      "Sucesso!",
      `Conteúdo ${!modalData ? "criado" : "editado"} com sucesso!`
    );
    setLoading(false);
    closeModal();
  };

  useEffect(() => {
    loadData();
    if (showModal && modalData) {
      setSelectedPillarId(modalData?._pillarId || null);
      setName(modalData?.name || "");
      setSelectedPillarId(modalData?._pillarId || "");
      setDescription(modalData?.description || "");
      setContentType(
        contents?.find((c) => c._id === modalData?._contentId) || null
      );
      setLink(modalData?.contentLink || "");
      setImg(modalData?.linkImage || "");
      setClassificationType(modalData?.classificationType);
      setSpeciality(
        specialities?.find((c) => c._id === modalData?.speciality) || null
      );
    } else {
      modalData = null;
    }
    return () => {
      setName("");
      setSelectedPillarId(null);
      setDescription("");
      setLink("");
      setImg("");
      setLinkError("");
      setImgError("");
    };
  }, [modalData]);

  return (
    <Modal show={showModal} onHide={closeModal} className="custom-modal ">
      <ModalContainer>
        <ModalHeader>
          <ModalTitle>
            {modalData !== null ? "Editar Conteúdo" : "Adicionar Conteúdo"}
          </ModalTitle>
          <CloseBtn>
            <IoIosClose
              onClick={closeModal}
              size={28}
              style={{ color: "gray", marginRight: 25, cursor: "pointer" }}
            />
          </CloseBtn>
        </ModalHeader>
        <ModalBody>
          <div className="mb-3">
            <label className="form-label shadow-none">Título *</label>
            <input
              type="text"
              className={`form-control ${nameError ? "is-invalid" : ""}`}
              value={name}
              onChange={setNameValue}
              required
            />
            {nameError && <div className="invalid-feedback">{nameError}</div>}
          </div>
          <div className="mb-3">
            <label className="form-label shadow-none">Tipo de Conteúdo *</label>
            <select
              className="form-select"
              value={contentType?._id || ""}
              onChange={setContentTypeValue}
              required
            >
              <option value="" disabled>
                Selecione
              </option>
              {contents.map((item) => {
                return (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label shadow-none">Especialidade</label>
            <select
              className="form-select"
              value={speciality?._id || ""}
              onChange={setSpecialityValue}
              required
            >
              <option value="" disabled>
                Selecione
              </option>
              {specialities.map((item) => {
                return (
                  <option key={item._id} value={item._id}>
                    {item.name}
                  </option>
                );
              })}
            </select>
          </div>
          <div className="mb-3">
            <label className="form-label shadow-none">Classificação *</label>
            <select
              className="form-select"
              value={classificationType || ""}
              onChange={setTypeValue}
              required
            >
              <option value={SupportContentTypeEnum.Care}>Cuidado</option>
              <option value={SupportContentTypeEnum.Inspiration}>
                Inspiração
              </option>
              <option value={SupportContentTypeEnum.Suggestions}>
                Sugestões
              </option>
            </select>
          </div>
          <label className="form-label shadow-none">Pilares *</label>
          <ModalPillars className="d-flex align-items-center mb-3">
            {pillars.map((pillarCardData) => (
              <PillarCenterCard
                className={`${
                  changeClassNamePillar(pillarCardData.name) +
                  " animate__animated animate__fadeIn animate__delay-0.5s pillars"
                } ${selectedPillarId === pillarCardData._id ? "active" : ""}`}
                onClick={() => handlePillarClick(pillarCardData._id)}
              >
                {changeIconPillar(pillarCardData.name)}
              </PillarCenterCard>
            ))}
          </ModalPillars>
          <div className="mb-3">
            <label className="form-label shadow-none">Imagem *</label>
            <input
              type="file"
              className={`form-control ${imgError ? "is-invalid" : ""}`}
              onChange={(e) => handleImageUpload(e)}
              required={!isYouTubeLink(contentLink || "")}
            />
            {imgError && <div className="invalid-feedback">{imgError}</div>}
          </div>

          <div className="mb-3">
            <label className="form-label shadow-none">Link </label>
            <input
              type="text"
              className={`form-control ${linkError ? "is-invalid" : ""}`}
              value={contentLink}
              onChange={setLinkValue}
            />
            {linkError && <div className="invalid-feedback">{linkError}</div>}
          </div>

          {img && (
            <div className="mb-3">
              <img
                src={img}
                alt="Imagem"
                style={{ maxWidth: "100%", height: "auto" }}
              />
            </div>
          )}

          <div className="mb-3">
            <label className="form-label shadow-none">Descrição *</label>
            <textarea
              className={`form-control shadow-none ${
                descriptionError ? "is-invalid" : ""
              }`}
              value={description}
              onChange={setDescriptionValue}
              required
            ></textarea>
            {descriptionError && (
              <div className="invalid-feedback">{descriptionError}</div>
            )}
          </div>

          {loading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "rgba(0, 0, 0, 0.5)",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <RingLoader color="#00df70" loading={true} />
            </div>
          )}
          {!loading && (
            <ModalBtnAdd style={{ background: "#00df70" }} onClick={saveData}>
              Salvar
            </ModalBtnAdd>
          )}
        </ModalBody>
      </ModalContainer>
    </Modal>
  );
};

export default ModalInsertContent;
